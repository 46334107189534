<template>
  <div>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="任务ID" prop="taskId">
                <a-input v-model="queryParam.taskId" placeholder="请输入任务ID" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="8" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="描述" prop="areaDesc">
                  <a-input v-model="queryParam.areaDesc" placeholder="请输入描述" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="围栏类型：1.暂停、2.停止" prop="type">
                  <a-select placeholder="请选择围栏类型：1.暂停、2.停止" v-model="queryParam.type" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in areaTypeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="几何类型" prop="geomType">
                  <a-select placeholder="请选择几何类型" v-model="queryParam.geomType" style="width: 100%" allow-clear>
                    <a-select-option>请选择字典生成</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <a-col :md="8" :sm="24">
                <a-form-item label="租户ID" prop="tenantId">
                  <a-input v-model="queryParam.tenantId" placeholder="请输入租户ID" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="状态(0正常，1停用)" prop="status">
                  <a-select placeholder="请选择状态(0正常，1停用)" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button
          type="primary"
          @click="$refs.createForm.handleAdd(queryParam.taskId)"
          v-hasPermi="['tenant:iot:vehicleTaskArea:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:iot:vehicleTaskArea:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tenant:iot:vehicleTaskArea:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleTaskArea:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :params="params" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false">
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskArea:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:vehicleTaskArea:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTaskArea:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicleTaskArea:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />
    </a-card>
  </div>
</template>

<script>
import { listVehicleTaskArea, delVehicleTaskArea, exportVehicleTaskArea, listExecVehicleTaskArea, delExecVehicleTaskArea, exportExecVehicleTaskArea } from '@/api/iot/vehicleTaskArea'
import CreateForm from './modules/CreateForm'

export default {
  name: 'VehicleTaskArea',
  components: {
    CreateForm
  },
  props: {
    breadcrumb: {
      type: Boolean,
      default: true
    },
    taskId: {
      type: String,
      default: ''
    },
    // 参数对象，包含当前组件所需要用到的props参数
    params: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      statusOptions: [],
      areaTypeOptions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        taskId: null,
        name: null,
        areaDesc: null,
        type: null,
        geomType: null,
        geomText: null,
        tenantId: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '区域ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '任务ID',
          dataIndex: 'taskId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '描述',
          dataIndex: 'areaDesc',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '围栏类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: (text, record) => {
            return record.type === 1 ? '暂停' : '停止'
          }
        },
        {
          title: '几何类型',
          dataIndex: 'geomType',
          ellipsis: true,
          align: 'center',
          customRender: (text, record) => {
            return record.geomType === 1 ? '圆形' : record.geomType === 2 ? '矩形' : record.geomType === 3 ? '多边形' : '路线'
          }
        },
        // {
        //   title: '几何数据',
        //   dataIndex: 'geomText',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '租户ID',
          dataIndex: 'tenantId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: (text, record) => {
            return record.status === 0 ? '正常' : '停用'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  async created() {
    console.log('params **** created', this.params)
    await this.getDicts('iot_task_area_type').then((response) => {
      this.areaTypeOptions = response.data
    })
    await this.getDicts('sys_normal_disable').then((response) => {
      this.statusOptions = response.data
    })
    this.queryParam.taskId = this.params.taskId
    this.getList()
  },
  computed: {
  },
  watch: {
    params(newVal, oldVal) {
      console.log('params', newVal)
      this.queryParam.taskId = newVal.taskId
      this.getList()
    }
  },
  methods: {
    /** 查询任务电子围栏列表 */
    getList() {
      this.loading = true
      // 当taskType='exec' 时调用接口为listExecVehicleTaskArea等，否则调用listVehicleTaskArea
      const apiToCall = this.params.taskType === 'exec' ? listExecVehicleTaskArea : listVehicleTaskArea
      console.log('this.params.taskType', this.params.taskType)
      console.log('apiToCall', apiToCall)
      apiToCall(this.queryParam || {}).then(response => {
        this.list = response.rows || []
        this.total = response.total || 0
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        taskId: undefined,
        name: undefined,
        areaDesc: undefined,
        type: undefined,
        geomType: undefined,
        geomText: undefined,
        tenantId: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          // 当taskType='exec' 时调用接口为delExecVehicleTaskArea等，否则调用delVehicleTaskArea
          const apiToDelete = that.params.taskType === 'exec' ? delExecVehicleTaskArea : delVehicleTaskArea
          return apiToDelete(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          // 当taskType='exec' 时调用接口为exportExecVehicleTaskArea等，否则调用exportVehicleTaskArea
          const apiToExport = that.params.taskType === 'exec' ? exportExecVehicleTaskArea : exportVehicleTaskArea
          return apiToExport(that.queryParam)
            .then(response => {
              that.download(response.msg)
              that.$message.success(
                '导出成功',
                3
              )
            })
        },
        onCancel() { }
      })
    }
  }
}
</script>
